import { Container } from '@/components/Container'

export function Footer() {
  return (
    <footer className="bg-paper">
      <Container>
        <div className="flex flex-col items-center border-t border-gray-200 pt-8 pb-12 md:flex-row md:justify-start md:pt-6">
          <p className="mt-6 font-figtree text-sm text-primary md:mt-0">
            &copy; 2024 Writana, Inc.
          </p>
          <a
            href="/terms-and-conditions"
            target="_blank"
            rel="noopener noreferrer"
            className="md:ml-8"
          >
            <p className="mt-6 font-figtree text-sm text-primary hover:underline md:mt-0">
              Terms and Conditions
            </p>
          </a>
          <a
            href="/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
            className="md:ml-8"
          >
            <p className="mt-6 font-figtree text-sm text-primary hover:underline md:mt-0">
              Privacy Policy
            </p>
          </a>
        </div>
      </Container>
    </footer>
  )
}
